<template>
  <div class="color-selection" v-bind:class="{ 'dark-tones': darkToneSelection }">
    <div class="color" v-for="(color, index) in colors" :key="index">
      <div
        class="dark"
        v-bind:style="{ background: color.dark }"
        v-bind:class="{
          big: !color.light,
          selected: colorSelected === color.dark,
        }"
        v-tooltip="GetTooltip(color.dark)"
        @click="SelectColor(color.dark, true)"
      ></div>
      <div
        class="light"
        v-if="color.light"
        v-bind:style="{ background: color.light }"
        v-bind:class="{ selected: colorSelected === color.light }"
        v-tooltip="GetTooltip(color.light)"
        @click="SelectColor(color.light)"
      ></div>
    </div>
    <div class="footer">
      <div class="align-left">
        <buttonc
          type="rectangle"
          label="Abbrechen"
          v-tooltip="'Abbrechen'"
          @click="Close()"
          style="width: 110px"
        />
      </div>
      <div class="align-right">
        <buttonc
          type="rectangle green"
          label="Speichern"
          v-tooltip="'Änderungen speichern'"
          @click="ApplyColor()"
          style="width: 110px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "colorselection",
  data() {
    return {
      darkToneSelection: true,
      colors: [
        { dark: "#996601", light: "#dbc9a6" },
        { dark: "#cdbca8", light: "#ede7e0" },
        { dark: "#fdd6b7", light: "#fdf1e6" },
        { dark: "#f99397", light: "#fdd9db" },
        { dark: "#db5382", light: "#f2c3d3" },
        { dark: "#a5163e", light: "#dfadbc" },
        { dark: "#c390c0", light: "#ead8e9" },
        { dark: "#3b366a", light: "#bab9cb" },
        { dark: "#005c90", light: "#a6c5d8" },
        { dark: "#8d9fd2", light: "#d7ddef" },
        { dark: "#6b8a94", light: "#cad6da" },
        { dark: "#2f8fac", light: "#b6d8e2" },
        { dark: "#4e704e", light: "#c1cdc1" },
        { dark: "#999966", light: "#dbdbc9" },
        { dark: "#79baa6", light: "#d0e7e0" },
        { dark: "#c6dadb", light: "#ebf2f2" },
      ],
      colorSelected: "",
    };
  },
  props: {
    color: String, //hex string
    //'#996601'
    colorBlacklist: {
      type: Array,
      default() {
        return [];
      },
    },
    //array of {color: '#996601', label: 'Service'}
    //use label to indicate which spot is taking up the slot
    colorSelection: Array,
    //array of hex strings or {dark: '#996601', light: 'dbc9a6'}
    //works either way
  },
  watch: {
    color: {
      immediate: true,
      handler(newVal, oldVal) {
        this.colorSelected = newVal;
        if (this.colors.find((c) => c.light === newVal)) this.darkToneSelection = false;
      },
    },
    colorSelection: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.colors = [];
        for (let key in newVal) {
          let color = newVal[key];
          if (color.dark) {
            this.colors.push(color);
          } else {
            this.colors.push({ dark: color });
          }
        }
      },
    },
  },
  methods: {
    SelectColor(color, isDark) {
      //if (this.colorBlacklist.find((c) => c.color === color)) return;
      this.darkToneSelection = isDark;
      this.colorSelected = color;
    },
    GetTooltip(color) {
      let blacklist = this.colorBlacklist.find((c) => c.color === color);
      if (!blacklist) return null;
      else return blacklist.label;
    },
    Close() {
      this.$parent.Close();
    },
    ApplyColor() {
      this.$emit("ApplyColor", this.colorSelected);
      this.$parent.Close();
    },
  },
};
</script>

<style scoped>
.color-selection {
  white-space: nowrap;
}

.color {
  height: 60px;
  width: 60px;
  float: left;
  margin: 5px;
  position: relative;
}
.color *.selected {
  pointer-events: none;
}

.color *.selected,
.color *:hover {
  box-shadow: 0 0 6px 0px var(--ml);
  transform: scale(1.1);
}

.color:nth-child(4n + 1) {
  clear: left;
}

.color:nth-last-child(2) {
  margin-bottom: 30px;
}

.footer {
  margin-bottom: -20px;
}

.color .light,
.color .dark {
  height: 40px;
  width: 40px;
  position: absolute;
  transition: 0.1s;
  cursor: pointer;
}

.color .dark {
  left: 0;
  top: 0;
  z-index: 2;
}

.color .light {
  right: 0;
  bottom: 0;
  z-index: 3;
}

.dark-tones .dark {
  z-index: 4;
}

.color *.disabled {
  z-index: 1;
  opacity: 1 !important;
  transform: scale(0.8);
  cursor: not-allowed !important;
  pointer-events: initial !important;
}

.color .light,
.color .dark {
}

.color .big {
  height: 60px;
  width: 60px;
}
</style>
